/* You can add global styles to this file, and also import other style files */
/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

html {
  height: 100%;
}

body {
  height: 100%;
}

html,
body {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box !important;
}

.sidebar-class {
  width: 80vw;
}

@media screen and (min-width: 768px) {
  .sidebar-class {
    width: 310px !important;
  }
}

.pointer {
  cursor: pointer;
}

.no-shadow {
  box-shadow: none !important;
}


ngb-typeahead-window {
  max-height: 25vh;
  overflow-y: auto;
  overflow-x: hidden;
}